import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';
import {
  CurrencyDollarIcon,
  CloudDownloadIcon,
  DesktopComputerIcon,
} from '@heroicons/react/outline';

import SEO from 'components/SEO';
import Link from 'components/Link';
import MainHero from 'components/MainHero';
import SideBox2 from 'components/videoproc/SideBox2';
// import SideBox3 from 'components/videoproc/SideBox3';
import Divider from 'components/Divider';
import Section from 'components/Section';
import SectionHeading from 'components/SectionHeading';
import PromotionSection from 'components/PromotionSection';
import FeaturesSection from 'components/FeaturesSection';
import FeaturesList from 'components/FeaturesList';
import { CURRENT_YEAR } from 'config';

export default function HomePage() {
  const { t } = useTranslation();

  return (
    <>
      <SEO
        title={t('home:meta.title', {
          info: `[${t('common:updated_year', { year: CURRENT_YEAR })}]`,
        })}
        description={t('home:meta.desc')}
        keywords={t('home:meta.keywords')}
      />

      <MainHero
        title={
          <Trans
            i18nKey="common:online_dl_conv"
            components={[
              <span className="text-blue-500" />,
              <span className="text-blue-500" />,
            ]}
          />
        }
        description={
          <Trans
            i18nKey="common:main_desc"
            components={[<Link href="/sites" className="underline" />]}
          />
        }
        aside={
          <div>
            <div className="mb-6">
              <SideBox2 />
            </div>
            {/* <SideBox3 /> */}
          </div>
        }
      />

      <Divider />

      <Section className="max-w-7xl mx-auto">
        <SectionHeading
          title={t('home:features.title')}
          subtitle={t('home:features.subtitle')}
        />
        <FeaturesList
          className="mt-12 sm:mt-24 lg:grid-cols-3"
          features={[
            {
              name: t('common:download'),
              description: (
                <Trans
                  i18nKey="home:features.dl_videos"
                  components={[
                    <Link
                      href="/sites"
                      className="font-medium text-gray-900 underline"
                    />,
                  ]}
                />
              ),
            },
            {
              name: t('common:convert'),
              description: t('home:features.conv_videos'),
            },
            {
              name: t('common:merge'),
              description: t('home:features.merge_videos'),
            },
            {
              name: t('common:cut'),
              description: t('home:features.cut_videos'),
            },
            {
              name: t('common:alt_dl'),
              description: t('home:features.alt_dl'),
            },
            {
              name: t('common:dl_subtitles'),
              description: t('home:features.dl_sub'),
            },
          ]}
        />
      </Section>

      <Divider />

      <PromotionSection />

      <Divider />

      <FeaturesSection
        features={[
          {
            name: t('home:feature_list.free'),
            description: t('home:feature_list.free_desc'),
            icon: CurrencyDollarIcon,
          },
          {
            name: t('home:feature_list.no_soft'),
            description: t('home:feature_list.no_soft_desc'),
            icon: CloudDownloadIcon,
          },
          {
            name: t('home:feature_list.any_device'),
            description: t('home:feature_list.any_device_desc'),
            icon: DesktopComputerIcon,
          },
        ]}
      />
    </>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "home", "videoproc"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
