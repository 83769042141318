import React from 'react';

export default function SectionHeading({ title, subtitle, className = '' }) {
  return (
    <div className={`max-w-3xl mx-auto text-center ${className}`}>
      <h2 className="text-3xl sm:text-4xl font-extrabold text-gray-900">
        {title}
      </h2>
      <p className="mt-4 text-lg text-gray-500">{subtitle}</p>
    </div>
  );
}
