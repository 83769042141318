import React from 'react';
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';

import Section from 'components/Section';
import useSiteMetadata from 'hooks/useSiteMetadata';
// import AppPromotion from 'components/videoproc/AppPromotion';

export default function PromotionSection() {
  const { t } = useTranslation();
  const site = useSiteMetadata();

  return (
    <Section py={false}>
      {/* <div className="lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-x-8"> */}
      <div className="max-w-3xl mx-auto">
        {/* <AppPromotion className="relative py-12 sm:py-24 lg:col-start-1" /> */}

        <div className="relative py-12 sm:py-24">
          <h2 className="text-sm font-semibold text-blue-500 tracking-wide uppercase">
            {t('home:mobile.downloader')}
          </h2>
          <p className="mt-3 text-3xl font-extrabold text-gray-900">
            <Trans
              i18nKey="home:mobile.title"
              components={[<span className="text-green-500" />]}
            />
          </p>
          <p className="mt-5 prose lg:prose-xl text-gray-700">
            <Trans
              i18nKey="home:mobile.p1"
              components={[
                <span className="font-bold" />,
                <span className="font-bold" />,
              ]}
            />
          </p>
          <p className="mt-5 prose lg:prose-xl">
            <Trans
              i18nKey="home:mobile.p2"
              values={{ sitename: site.title }}
              components={[
                <span className="font-bold" />,
                <span className="font-bold" />,
              ]}
            />
          </p>
        </div>
      </div>
    </Section>
  );
}
