import React from 'react';
import { CheckIcon } from '@heroicons/react/outline';
import { useTranslation } from 'gatsby-plugin-react-i18next';

export default function FeaturesList({ features, className = '' }) {
  const { i18n } = useTranslation();
  const isRTL = i18n.dir() === 'rtl';

  const margin = isRTL ? 'mr-9' : 'ml-9';

  return (
    <dl
      className={`space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:gap-x-8 ${className}`}
    >
      {features.map((feature) => (
        <div key={feature.name} className="relative">
          <dt>
            <CheckIcon
              className="absolute h-6 w-6 text-green-500"
              aria-hidden="true"
            />
            <p
              className={`text-lg leading-6 font-medium text-gray-900 ${margin}`}
            >
              {feature.name}
            </p>
          </dt>
          <dd className={`mt-2 text-base text-gray-500 ${margin}`}>
            {feature.description}
          </dd>
        </div>
      ))}
    </dl>
  );
}
